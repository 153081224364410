import React from 'react';
import styled from 'styled-components';

import { Temperature as TemperatureEnum } from '../../../generated/types';

import { TemperatureEmptyIcon, TemperatureFullIcon, TemperatureHalfIcon } from '../Icons';
import { Tooltip } from '../Tooltip';
import { IconButton } from '../Icons/Icon';
import { Colors } from '../../../styles/colors';

export const Temperature = ({ type = TemperatureEnum.Normal }: TemperatureProps) => {
  let icon = <></>;
  switch (type) {
    case TemperatureEnum.Cold:
      icon = (
        <Tooltip
          title={
            <IconButton type="button">
              <TemperatureEmptyIcon size={0.5} color={Colors.PrimaryLightest} />
            </IconButton>
          }
        >
          Cold: Old Job application with low Priority. Do you need to delete it?
        </Tooltip>
      );
      break;

    case TemperatureEnum.Normal:
      icon = (
        <Tooltip
          title={
            <IconButton type="button">
              <TemperatureHalfIcon size={0.5} color={Colors.Primary} />
            </IconButton>
          }
        >
          Follow-up: Job application requiring further attention or follow-up.
        </Tooltip>
      );
      break;
    case TemperatureEnum.Hot:
      icon = (
        <Tooltip
          title={
            <IconButton type="button">
              <TemperatureFullIcon size={0.5} color={Colors.PrimaryDarkest} />
            </IconButton>
          }
        >
          Hot: New or recently updated job application - high priority.
        </Tooltip>
      );
      break;
  }

  return <Container>{icon}</Container>;
};

export interface TemperatureProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: TemperatureEnum;
}

const Container = styled.div``;
