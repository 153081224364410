import { EmotionNormal } from '@styled-icons/remix-fill';
import { Icon, IconProps } from './Icon';

export function EmotionNormalIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <EmotionNormal />
    </Icon>
  );
}
