import React, { forwardRef, useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import styled from 'styled-components';

import { Colors } from '../../../styles/colors';
import { Job, JobStatus, User, Feeling as FeelingEnum, Temperature as TemperatureEnum } from '../../../generated/types';

import {
  EditIcon,
  SortDownIcon,
  LastPageIcon,
  FirstPageIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  SearchIcon,
  CalendarPlusIcon,
  MailSendIcon,
  ViewIcon,
  PeopleIcon,
  DeleteIcon,
  ChevronDownIcon,
  EmotionHappyIcon,
  EmotionNormalIcon,
  EmotionUnhappyIcon,
  TemperatureFullIcon,
  TemperatureEmptyIcon,
  TemperatureHalfIcon,
  EmotionEcstaticIcon,
} from '../../../components/atoms/Icons';
import { ClearIcon } from '../../../components/atoms/Icons/ClearIcon';
import { DropdownMenu, DropdownMenuItem } from '../../../components/atoms/DropdownMenu/DropdownMenu';
import { Spacer } from '../../../components/atoms/Spacer/Spacer';
import { ErrorBoundary } from '../../../components/molecules/ErrorBoundary';
import { JobTableRate, JobTableStatus } from '../../../components/molecules/JobTable';
import {
  JobTableAgency,
  JobTableCompany,
  JobTableDate,
  JobTablePosition,
} from '../../../components/molecules/JobTable/JobTable';
import { Feeling } from '../../../components/atoms/Feeling';
import { IconButton } from '../../../components/atoms/Icons/Icon';
import { Temperature } from '../../../components/atoms/Temperature';

export const JobsViewTable: React.FC<JobsViewTableProps> = ({
  handleViewClick,
  handleEditClick,
  handleAddEventClick,
  handleAddContactClick,
  handleDeleteClick,
  handleInviteClick,
  handleFeelingSelect,
  jobs,
}: JobsViewTableProps) => {
  // https://stackoverflow.com/questions/59648434/material-table-typeerror-cannot-add-property-tabledata-object-is-not-extensibl
  // Bug so we need to clone the data
  // Icons
  // https://stackoverflow.com/questions/63647493/react-material-table-is-not-displaying-table-icons
  const [selectedRow, setSelectedRow] = useState(null);
  const [feeling, setFeeling] = useState<FeelingEnum | undefined>(undefined);
  const [temperature, setTemperature] = useState<TemperatureEnum | undefined>(undefined);

  const cloneData = jobs
    .filter((job) => {
      if (feeling) {
        return job.feeling === feeling;
      }
      return true;
    })
    .filter((job) => {
      if (temperature) {
        return job.temperature === temperature;
      }
      return true;
    })
    .map((job) => ({ ...job }));

  return (
    <ErrorBoundary>
      {/* <pre>{JSON.stringify(jobs, null, 2)}</pre> */}
      <MaterialTable
        icons={tableIcons}
        columns={[
          {
            title: '',
            field: 'extra',
            filtering: false,
            searchable: false,
            width: '0px',
            render: ({
              uuid,
              feeling,
              temperature,
              status,
              updatedAt,
              appliedAt,
              interviewedAt,
              offerAt,
              rejectedAt,
              acceptedAt,
            }) => {
              return (
                <ExtraActions>
                  <Temperature type={temperature || TemperatureEnum.Normal} />
                  <Feeling
                    type={feeling || FeelingEnum.Normal}
                    onSelect={(feeling) => {
                      handleFeelingSelect(uuid, feeling);
                    }}
                  />
                  <JobTableDate
                    status={status}
                    updatedAt={updatedAt}
                    appliedAt={appliedAt}
                    interviewedAt={interviewedAt}
                    offerAt={offerAt}
                    rejectedAt={rejectedAt}
                    acceptedAt={acceptedAt}
                  />
                </ExtraActions>
              );
            },
          },
          {
            title: 'Agency',
            filtering: true,
            searchable: true,
            customFilterAndSearch: (term, rowData) => {
              const agencyName = rowData?.agencyName?.toLowerCase() || '';
              return agencyName.includes(term);
            },
            field: 'agencyName',
            render: (job) => {
              return (
                <JobTableAgency
                  agencyName={job.agencyName}
                  agentName={job.agentName}
                  agentPhone={job.agentPhone}
                  isThroughAgency={job.isThroughAgency}
                />
              );
            },
          },

          {
            title: 'Company',
            field: 'company',
            filtering: true,
            searchable: true,
            customFilterAndSearch: (term, rowData) => {
              const company = rowData?.company?.toLowerCase() || '';
              return company.includes(term);
            },
            render: (job) => {
              return (
                <JobTableCompany
                  companyAvatar={job.companyAvatar}
                  company={job.company}
                  companyDistances={job.companyDistances}
                  companyLocationCity={job.companyLocationCity}
                />
              );
            },
          },
          {
            title: 'Position',
            field: 'jobTitle',
            filtering: false,
            render: (job) => {
              return (
                <JobTablePosition
                  onClick={() => {
                    handleViewClick(job?.uuid);
                  }}
                  jobTitle={job.jobTitle}
                  jobTitleUrl={job.jobUrl}
                  isNew={job.isNew}
                />
              );
            },
          },
          {
            title: 'Rate',
            filtering: false,
            field: 'rate',
            render: (job) => <JobTableRate ir35={job.ir35} rate={job.rate} duration={job.duration}></JobTableRate>,
          },
          {
            title: 'Status',
            filtering: false,
            field: 'rate',
            render: (job) => <JobTableStatus status={job.status}></JobTableStatus>,
          },
          // {
          //   title: 'Score',
          //   filtering: false,
          //   field: 'score',
          //   render: (job) =>
          //     user?.isMember ? (
          //       job.aiMatchingSkillsPercentage
          //     ) : (
          //       <>
          //         <CrownIcon color={Colors.Primary} />
          //       </>
          //     ),
          // },
          {
            title: 'Extra Actions',
            filtering: false,
            field: 'uuid',
            render: ({ uuid, permissions, status }) => (
              <>
                {![JobStatus.Scrapped, JobStatus.Closed].includes(status) ? (
                  <ExtraActions>
                    <DropdownMenu>
                      <>
                        {permissions?.includes('ADD_EVENT') && (
                          <DropdownMenuItem onClick={() => handleAddEventClick(uuid)}>
                            <CalendarPlusIcon size={1.3} /> <Spacer x={8} />
                            Add Event
                          </DropdownMenuItem>
                        )}
                        {permissions?.includes('ADD_CONTACT') && (
                          <DropdownMenuItem onClick={() => handleAddContactClick(uuid)}>
                            <PeopleIcon size={1.3} /> <Spacer x={8} />
                            Add Contact
                          </DropdownMenuItem>
                        )}
                        {permissions?.includes('INVITE_JOB') && (
                          <DropdownMenuItem onClick={() => handleInviteClick(uuid)}>
                            <MailSendIcon size={1.3} /> <Spacer x={8} />
                            Invite
                          </DropdownMenuItem>
                        )}
                        {permissions?.includes('ARCHIVE_JOB') && (
                          <DropdownMenuItem onClick={() => handleDeleteClick(uuid)}>
                            <DeleteIcon size={1.3} /> <Spacer x={8} />
                            Delete Job
                          </DropdownMenuItem>
                        )}
                      </>
                    </DropdownMenu>
                  </ExtraActions>
                ) : null}
                {[JobStatus.Scrapped].includes(status) ? (
                  <ExtraActions>
                    <DropdownMenu>
                      <>
                        {permissions?.includes('ARCHIVE_JOB') && (
                          <DropdownMenuItem onClick={() => handleDeleteClick(uuid)}>
                            <DeleteIcon size={1.3} /> <Spacer x={8} />
                            Delete Job
                          </DropdownMenuItem>
                        )}
                      </>
                    </DropdownMenu>
                  </ExtraActions>
                ) : null}
              </>
            ),
          },
        ]}
        data={cloneData}
        title=""
        actions={[
          () => ({
            icon: () => <ViewIcon />,
            tooltip: 'View Job Application',
            onClick: (event, rowData) => {
              event.preventDefault();
              // @ts-ignore-next-line
              handleViewClick(rowData?.uuid);
            },
          }),
          (rowData) => ({
            icon: () => <EditIcon size={1.2} />,
            tooltip: 'Edit Job Application',
            onClick: (event, rowData) => {
              event.preventDefault();
              handleEditClick((rowData as Job)?.uuid);
            },
            hidden: [JobStatus.Closed].includes(rowData?.status as JobStatus),
          }),
        ]}
        onRowClick={(_event, selectedRow) => setSelectedRow(selectedRow?.tableData?.id)}
        options={{
          sorting: false,
          filtering: cloneData.length > 0,
          search: cloneData.length > 0,
          rowStyle: (rowData) => ({
            backgroundColor: selectedRow === rowData.tableData.id ? Colors.ContrastOverlay : '#FFF',
          }),
          actionsCellStyle: {
            // borderBottom: `1px solid red`,
          },
          filterCellStyle: {
            // borderBottom: `1px solid red`,
            padding: '2px 0px',
          },
          headerStyle: {
            // borderBottom: `1px solid red`,
            padding: '2px 0px',
          },
          showTitle: false,
          header: cloneData.length > 0,
          padding: 'dense',
          pageSize: 10,
          pageSizeOptions: [10, 20, 50],
        }}
        style={{
          boxShadow: 'none',
          borderRadius: 0,
          border: 'none',
          borderBottom: `1px solid ${Colors.ContrastLighter}`,
          padding: 0,
          margin: 0,
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <ToolbarActions>
                <IconButton
                  onClick={() => {
                    setFeeling((feeling) => (feeling === FeelingEnum.Sad ? undefined : FeelingEnum.Sad));
                  }}
                >
                  <EmotionUnhappyIcon size={1} color={feeling === FeelingEnum.Sad ? Colors.Primary : Colors.Contrast} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setFeeling((feeling) => (feeling === FeelingEnum.Normal ? undefined : FeelingEnum.Normal));
                  }}
                >
                  <EmotionNormalIcon
                    size={1}
                    color={feeling === FeelingEnum.Normal ? Colors.Primary : Colors.Contrast}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setFeeling((feeling) => (feeling === FeelingEnum.Happy ? undefined : FeelingEnum.Happy));
                  }}
                >
                  <EmotionHappyIcon size={1} color={feeling === FeelingEnum.Happy ? Colors.Primary : Colors.Contrast} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setFeeling((feeling) => (feeling === FeelingEnum.Ecstatic ? undefined : FeelingEnum.Ecstatic));
                  }}
                >
                  <EmotionEcstaticIcon
                    size={1}
                    color={feeling === FeelingEnum.Ecstatic ? Colors.Primary : Colors.Contrast}
                  />
                </IconButton>
                <Spacer y={16} />
                <IconButton
                  onClick={() => {
                    setTemperature((temperature) =>
                      temperature === TemperatureEnum.Cold ? undefined : TemperatureEnum.Cold
                    );
                  }}
                >
                  <TemperatureEmptyIcon
                    size={0.6}
                    color={temperature === TemperatureEnum.Cold ? Colors.Primary : Colors.Contrast}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setTemperature((temperature) =>
                      temperature === TemperatureEnum.Normal ? undefined : TemperatureEnum.Normal
                    );
                  }}
                >
                  <TemperatureHalfIcon
                    size={0.6}
                    color={temperature === TemperatureEnum.Normal ? Colors.Primary : Colors.Contrast}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setTemperature((temperature) =>
                      temperature === TemperatureEnum.Hot ? undefined : TemperatureEnum.Hot
                    );
                  }}
                >
                  <TemperatureFullIcon
                    size={0.6}
                    color={temperature === TemperatureEnum.Hot ? Colors.Primary : Colors.Contrast}
                  />
                </IconButton>
              </ToolbarActions>
            </div>
          ),
        }}
      />
    </ErrorBoundary>
  );
};

export default JobsViewTable;

const ToolbarActions = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-top: -42px;
`;

const ExtraActions = styled.div`
  display: flex;
  gap: 16px;
`;

const tableIcons: any = {
  FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <SortDownIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeftIcon {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <ChevronDownIcon {...props} ref={ref} />),
};

interface JobsViewTableProps {
  user: User | null;
  jobs: Job[];
  handleViewClick: (jobUuid: string) => void;
  handleEditClick: (jobUuid: string) => void;
  handleDeleteClick: (jobUuid: string) => void;
  handleInviteClick: (jobUuid: string) => void;
  handleAddEventClick: (jobUuid: string) => void;
  handleAddContactClick: (jobUuid: string) => void;
  handleFeelingSelect: (jobUuid: string, feeling: FeelingEnum) => void;
  // handleJobStatusSelect: (jobUuid: string, status: JobStatus) => void;
}
