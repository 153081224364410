import { CalendarPlus } from '@styled-icons/fa-regular';
import { Icon, IconProps } from './Icon';

export function CalendarPlusIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <CalendarPlus />
    </Icon>
  );
}
