import { EmotionUnhappy } from '@styled-icons/remix-fill';
import { Icon, IconProps } from './Icon';

export function EmotionUnhappyIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <EmotionUnhappy />
    </Icon>
  );
}
