import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../../../styles/colors';
import { Feeling as FeelingEnum } from '../../../generated/types';

import { EmotionHappyIcon, EmotionEcstaticIcon, EmotionNormalIcon, EmotionUnhappyIcon } from '../Icons';
import { DropdownMenu } from '../DropdownMenu';
import { Spacer } from '../Spacer/Spacer';
import { DropdownMenuItem } from '../DropdownMenu/DropdownMenu';

export const Feeling = ({ onSelect, type = FeelingEnum.Normal }: FeelingProps) => {
  let icon = <></>;
  switch (type) {
    case FeelingEnum.Sad:
      icon = <EmotionUnhappyIcon size={1.7} />;
      break;
    case FeelingEnum.Normal:
      icon = <EmotionNormalIcon size={1.7} />;
      break;
    case FeelingEnum.Happy:
      icon = <EmotionHappyIcon size={1.7} />;
      break;
    case FeelingEnum.Ecstatic:
      icon = <EmotionEcstaticIcon size={1.7} />;
      break;
  }

  return (
    <Container>
      <DropdownMenu icon={icon}>
        <>
          <DropdownMenuItem onClick={() => onSelect(FeelingEnum.Sad)}>
            <EmotionUnhappyIcon size={1.6} /> <Spacer x={8} />
            Yeah... Not so good
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSelect(FeelingEnum.Normal)}>
            <EmotionNormalIcon size={1.6} /> <Spacer x={8} />
            Normal
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSelect(FeelingEnum.Happy)}>
            <EmotionHappyIcon size={1.6} /> <Spacer x={8} />
            Feeling Good
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSelect(FeelingEnum.Ecstatic)}>
            <EmotionEcstaticIcon size={1.6} /> <Spacer x={8} />
            Feeling Awesome
          </DropdownMenuItem>
        </>
      </DropdownMenu>
    </Container>
  );
};

export interface FeelingProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: FeelingEnum;
  onSelect: (feeling: FeelingEnum) => void;
}

const Container = styled.div``;

const Button = styled.button<FeelingProps>`
  ${({ type }) => {
    switch (type) {
      case FeelingEnum.Normal:
        return css`
          color: ${Colors.Primary};
        `;
      case FeelingEnum.Happy:
        return css`
          color: ${Colors.PrimaryDark};
        `;
      case FeelingEnum.Ecstatic:
        return css`
          color: ${Colors.PrimaryDarker};
        `;
      case FeelingEnum.Sad:
        return css`
          color: ${Colors.Contrast};
        `;
    }
  }}
`;
