import { keyframes } from 'styled-components';

import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { HamburgerMenuIcon, DotFilledIcon, CheckIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';
import { Colors } from '../../../styles/colors';

export const DropdownMenu = ({
  children,
  icon,
}: React.PropsWithChildren<{
  icon?: React.ReactNode;
}>) => {
  return (
    <RadixDropdownMenu.Root>
      <RadixDropdownMenu.Trigger asChild>
        <IconButton aria-label="actions">{icon ? icon : <HamburgerMenuIcon />}</IconButton>
      </RadixDropdownMenu.Trigger>
      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.Content sideOffset={5}>
          <DropdownMenuContent>{children}</DropdownMenuContent>
          <DropdownMenuArrow />
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};

export const DropdownMenuContent = styled.div`
  min-width: 220px;
  background-color: white;
  border-radius: 8px;
  padding: 8px 4px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side='top'] {
    animation-name: slideDownAndFade;
  }

  &[data-side='right'] {
    animation-name: slideLeftAndFade;
  }

  &[data-side='bottom'] {
    animation-name: slideUpAndFade;
  }

  &[data-side='left'] {
    animation-name: slideRightAndFade;
  }
`;

export const DropdownMenuSubContent = styled(RadixDropdownMenu.SubContent)`
  min-width: 220px;
  background-color: white;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side='top'] {
    animation-name: slideDownAndFade;
  }
`;

export const DropdownMenuItem = styled(RadixDropdownMenu.Item)`
  font-size: 16px;
  line-height: 1;
  color: ${Colors.Black};
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 5px;
  position: relative;
  padding-left: 5px;
  user-select: none;
  outline: none;

  &[data-highlighted] {
    background-color: ${Colors.Contrast};
    cursor: pointer;
  }

  &[data-disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
`;

export const DropdownMenuCheckboxItem = styled(RadixDropdownMenu.CheckboxItem)`
  font-size: 13px;
  line-height: 1;
  color: ${Colors.Contrast};
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 5px;
  position: relative;
  padding-left: 25px;
  user-select: none;
  outline: none;
`;

export const DropdownMenuSeparator = styled(RadixDropdownMenu.Separator)`
  height: 1px;
  background-color: ${Colors.Contrast};
  margin: 5px;
`;

export const DropdownMenuItemIndicator = styled(RadixDropdownMenu.ItemIndicator)`
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownMenuArrow = styled(RadixDropdownMenu.Arrow)`
  fill: white;
`;

export const IconButton = styled.button`
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Contrast};
  background-color: white;
  box-shadow: 0 2px 10px ${Colors.Contrast};

  :hover {
    background-color: ${Colors.ContrastLightest};
  }

  :focus {
    box-shadow: 0 0 0 1px ${Colors.ContrastLightest};
  }
`;

const RightSlot = styled.div`
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve-11);
`;

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});
