import { HappyHeartEyes } from '@styled-icons/boxicons-solid';
import { Icon, IconProps } from './Icon';

export function EmotionEcstaticIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <HappyHeartEyes />
    </Icon>
  );
}
