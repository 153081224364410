import { TemperatureEmpty, TemperatureFull, TemperatureHalf } from '@styled-icons/fa-solid/';
import { Icon, IconProps } from './Icon';

export function TemperatureEmptyIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <TemperatureEmpty />
    </Icon>
  );
}

export function TemperatureFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <TemperatureFull />
    </Icon>
  );
}

export function TemperatureHalfIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <TemperatureHalf />
    </Icon>
  );
}
